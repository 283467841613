import { Box, createTheme, Typography } from "@mui/material";
import { useMemo, useCallback, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { GameInfo, qrScanErrorMessages, QRScannerSettings } from "../utils/common";
import { useAppDispatch } from "../redux/hooks";
import { openSnackbar } from "../redux/toggleSnackbarSlice";
import { NotifType } from "./customSnackbarComponent/CustomSnackbarComponent";
import { ApiResponseCodesEnum, postNewEntry, PostNewEntryRequestData } from "../utils/restAPI";
import { ApiError } from "../utils/error";
import { openImageModal } from "../redux/toggleImageModalSlice";

export interface UserEntryPageProp {
    readonly title: string
    readonly qrReaderLabel: string
    readonly currentGame?: GameInfo | undefined
}


const MIN_CONTAINER_WIDTH = 300;

export const UserEntryPage = (props: UserEntryPageProp) => {
    const dispatcher = useAppDispatch();
    const {title, qrReaderLabel, currentGame} = props;
    const theme = createTheme();
    const lastTicketCode = useRef<string | undefined>(undefined);

    const handleQRScanResult = useCallback(async (result, error) => {
        if (!!result) {
            const newTicketCode = result.getText();
            if(currentGame !== undefined && lastTicketCode.current !== newTicketCode){
                const data: PostNewEntryRequestData = {
                    ticketCode: newTicketCode
                };
                const response = await postNewEntry(currentGame.eventUuid , data);

                if(response instanceof ApiError){
                    dispatcher(openImageModal({
                        isOpen: true,
                        duration: 3000,
                        responseCode: response.code ?? response.name,
                        playSound: true,
                    }));
                } else {
                    dispatcher(openImageModal({
                        isOpen: true,
                        duration: 3000,
                        responseCode: ApiResponseCodesEnum.SUCCESS,
                        playSound: true,
                    }));
                    lastTicketCode.current = newTicketCode;
                }
            }
        }

        if (!!error && error.message !== undefined) {
            console.info(error);
            dispatcher(openSnackbar({
                isOpen: true,
                type: NotifType.ERROR,
                // The defualt message is failed to read qr code
                message: qrScanErrorMessages[error.message] ?? qrScanErrorMessages['defaultReadFailMessage'], 
                duration: 2000,
            }));
        }
    },[currentGame, dispatcher])
   
    /*
    * This JSON controls the style of video showing up on the page.
    */
    const qrReaderSettings = useMemo<QRScannerSettings>(()=>{
        return {
            onResult: handleQRScanResult,
            scanDelay: 300,

            // This restricts the size of video showing on the UI.
            // It is difficult to find optimal minWidth to fit all smartphones.
            containerStyle: {
                minWidth: MIN_CONTAINER_WIDTH,
            },
            videoContainerStyle: {
                // By default paddingTop is set at 100%. Making adjustment to fit it correctly.
                paddingTop: '85%',
            },
            constraints: {
                facingMode: 'user',
            },
        }
    },[handleQRScanResult])

    return (
        <Box
            className='rootContainer'
        >
            <Box
                className='titleContainer'
            >   
                <Box
                    className='title'
                    sx={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        marginTop: theme.spacing(1),
                    }}
                >
                    {title}
                </Box>
                <Box
                    className='description'
                        sx={{
                            color:'#FF0000',
                        }}
                    >
                        <span>
                            {qrReaderLabel}
                        </span>                
                </Box>
            </Box>
            <Box
                className='eventTitleContainer'
                sx={{
                    textAlign: 'center',
                    marginTop: theme.spacing(2),
                }}
            >
                <Typography
                    variant='h5'
                >
                    {currentGame?.title ?? ''}
                </Typography>  
            </Box>  
            <Box
                className='userEntryContainer'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box 
                    className='qrReaderContainer'
                    sx={{   
                        minWidth: MIN_CONTAINER_WIDTH,  
                    }}
                >
                    {currentGame && (
                        <QrReader
                            key={currentGame.eventUuid}
                            {...qrReaderSettings}
                        />
                    )}
                </Box>
            </Box>
        </Box>  
    );
}