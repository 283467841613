import { useCallback } from 'react';
import { Box, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { closeSnackbar } from '../../redux/toggleSnackbarSlice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';

export enum NotifType {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export const CustomSnackbarComponent = () => {
    const {isOpen, type, message, duration } = useAppSelector((state:RootState)=> state.toggleSnackbarReducer);
    const dispatcher = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatcher(closeSnackbar());
    },[dispatcher])

    return (
        <Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={isOpen}
                autoHideDuration={duration}
                onClose={handleClose}
            >
                <Alert className='alert'
                    onClose={handleClose} 
                    severity={type}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
}