import {TextField, InputLabelProps} from "@mui/material";

export interface InputItemSettings {
    readonly required?: boolean | undefined
    readonly className?: string | undefined
    readonly label?: string | undefined
    readonly placeholder?: string | undefined
    readonly isQRCodeInput?: boolean | undefined
    readonly name?: string | undefined
    readonly helperText?: string | undefined
    readonly isReadonly?: boolean | undefined
}

export interface CustomTextFieldComponentProp extends InputItemSettings {
    readonly value?: string | undefined
    readonly inputLabelProps?: InputLabelProps | undefined
    readonly onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void | undefined
    readonly isValidInput?: boolean | undefined
}

export const CustomTextFieldComponent = (props: CustomTextFieldComponentProp) => {
    const {required, className, label, placeholder, inputLabelProps, value, onChange, name, isValidInput, helperText, isReadonly } = props;
    const defaultInputLabelProp: InputLabelProps = { shrink: true, style:{color:'#FF0000'}};

    return (
        <TextField
            required={required}
            className={className}
            label={label}
            placeholder={placeholder}
            value={value}
            InputLabelProps={{...defaultInputLabelProp, ...inputLabelProps}}
            name={name}
            error={isValidInput === false}
            helperText={helperText}
            inputProps={{readOnly: isReadonly}}
            onChange={onChange}
        />
    )
}