import axios, { isAxiosError } from 'axios';
import { SeasonGameList } from './common';
import { ApiError } from './error';

type SportsType = 'soccer';
type GameType = 'normal' | 'practice' | 'friendly';

/*
* These codes are based on backend HTTP API response
*/
export enum ApiResponseCodesEnum {
    SUCCESS = 'success',
    ALREADY_ENTERED = 'already_entered',
    ALREADY_ASSIGNED = 'already_assigned',
    TICKET_EXPIRED = 'ticket_expired',
    TICKET_NOT_FOUND = 'ticket_not_found',
    UNABLE_DATE_ENTRY = 'unable_date_entry',
    DB_OPERATION_FAILED = 'db_operation_failed',
    VALIDATION_ERROR = 'validation_error',
}

export interface GetGamesQueryParams {
    readonly code?: string | undefined;
    readonly gameType?: GameType | undefined;
    readonly homeCode?: string | undefined;
    readonly kind?: SportsType | undefined;
    readonly limit?: number | undefined;
    readonly newerThan?: string | undefined;
    readonly offset?: number | undefined;
    readonly team?: string | undefined;
}

export interface PostNewSupporterRequestData {
    readonly firstName: string;
    readonly lastName: string;
    readonly membershipId: string;
    readonly ticketCode: string;
}

interface TicketInfo {
    readonly description: string;
    readonly extra: string | null;
    readonly ticketType: string;
    readonly title: string;
}

export interface PostNewSupporterResponseData {
    readonly firstName: string;
    readonly lastName: string;
    readonly membershipId: string;
    readonly ticket: TicketInfo;
    readonly uniqueId: string;
}

export interface PostNewEntryRequestData {
    readonly ticketCode: string;
}

export interface ErrorResponse {
    readonly code: string;

    // details only exist for 500 level error.
    // Key is exception name and value contains a message associated with the exception
    readonly details?: object | undefined;
    readonly message: string;
}

const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
    const errorResponse = obj as ErrorResponse;
    return (
        errorResponse &&
        typeof errorResponse.code === 'string' &&
        (errorResponse.details === undefined ||
            (!Array.isArray(errorResponse.details) &&
                typeof errorResponse.details === 'object')) &&
        typeof errorResponse.message === 'string'
    );
};
const handleErrorResponse = (error: unknown): ApiError => {
    if (
        isAxiosError(error) &&
        error?.response &&
        isErrorResponse(error.response.data)
    ) {
        const { code, details, message } = error.response.data;
        return new ApiError(code, message, details);
    } else if (error instanceof Error) {
        return new ApiError(error.name, error.message);
    }
    return new ApiError('UNEXPECTED_ERROR', 'Unexpected error occurred');
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

/*
 * This handler gets list of games for the season
 */
export const getGames = async (
    params: GetGamesQueryParams
): Promise<SeasonGameList | ApiError> => {
    try {
        const response = await axios.get<SeasonGameList>(
            `${BASE_URL}/v1/games`,
            {
                headers: {
                    Accept: 'application/json',
                },
                params,
            }
        );
        return response.data;
    } catch (error) {
        return handleErrorResponse(error);
    }
};

/*
 * This handler sends a new entry of the ticket
 */
export const postNewEntry = async (
    eventUuid: string,
    data: PostNewEntryRequestData
): Promise<number | ApiError> => {
    try {
        const response = await axios.post(
            `${BASE_URL}/v1/events/${eventUuid}/entry`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }
        );
        // response data from backend is empty
        return response.status;
    } catch (error) {
        return handleErrorResponse(error);
    }
};

/*
 * This handler associates ticketCode to the membershipId
 */
export const postNewSupporter = async (
    data: PostNewSupporterRequestData
): Promise<PostNewSupporterResponseData | ApiError> => {
    try {
        const response = await axios.post<PostNewSupporterResponseData>(
            `${BASE_URL}/v1/supporters`,
           data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        return handleErrorResponse(error);
    }
};
