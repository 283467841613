import { Box, createTheme, styled, Button, FormControl } from "@mui/material";
import React, { useMemo } from "react";
import { QrReader } from 'react-qr-reader';
import { QRScannerSettings } from "../utils/common";
import { CustomTextFieldComponent, InputItemSettings } from "./customTextFieldComponent/CustomTextFieldComponent";
import { useAppDispatch } from "../redux/hooks";
import { openSnackbar } from "../redux/toggleSnackbarSlice";
import { NotifType } from "./customSnackbarComponent/CustomSnackbarComponent";
import { qrScanErrorMessages } from "../utils/common";
import clsx from "clsx";

const MIN_CONTAINER_WIDTH = 300;
const MAX_CONTAINER_WIDTH = 400;
const MAX_CONTAINER_HEIGHT= 400;


export interface UserRegistrationInputFields extends InputItemSettings{
    readonly validator: (input: string) => boolean
    readonly clearInputButton?: boolean | undefined
}

export interface UserRegistrationInputFieldsSet{
    readonly membershipId?: UserRegistrationInputFields
    readonly lastName?: UserRegistrationInputFields
    readonly firstName?: UserRegistrationInputFields
    readonly ticketCode?: UserRegistrationInputFields
}

export interface FormInputFields {
    readonly input: string 
    readonly isValid: boolean
}

export interface UserRegistrationInputItems {
    readonly membershipId: FormInputFields
    readonly lastName: FormInputFields
    readonly firstName: FormInputFields
    readonly ticketCode: FormInputFields
}

export interface UserRegistrationPageProp {
    readonly title: string
    readonly inputItems: UserRegistrationInputFieldsSet
    readonly buttonText: string
    readonly formInputs: UserRegistrationInputItems
    readonly onSubmit: () => void
    readonly onInputChange: (name: string, value: string, validator: (input: string) => boolean) => void
}

const theme = createTheme();
const FormContainerStyleBox = styled(Box)({
    // added margin at the bottom to make sure there is enough space at the bottom so that button is pressible
    marginBottom: 125,  

    ' .titleContainer':{
        fontWeight: 'bold',
        fontSize: 24,
        marginTop: theme.spacing(2),
    },

    ' .userRegistrationContainer':{
          // Setting minWidth, otherwise video screen collapose in to 0 by 0 pixels
          minWidth: MIN_CONTAINER_WIDTH,
          display: 'flex',
          flexDirection: 'row',
          [`${theme.breakpoints.down('md')}`]: {
            flexDirection: 'column',
          },
          alignContent:'center',
          alignItem: 'center',
          gap: theme.spacing(2),

        ' .formContainer':{             
            display: 'flex',
            flexDirection: 'column',
            maxWidth: MAX_CONTAINER_WIDTH,
            marginBottom: theme.spacing(1.5),
            alignItem: 'center',

            ' .textFieldContainer':{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: theme.spacing(2),

                ' .textField':{
                    flex:1,
                },

                ' .textField.withClearButton':{
                    // no styling
                },

                ' .clearButton':{
                    marginLeft: theme.spacing(1),
                }
            },
        },
    },
});

export const UserRegistrationPage = (props: UserRegistrationPageProp) => {
    const dispatcher = useAppDispatch();

    /*
    * Memoized settings and hanlders required for QR code scanner component.
    * onResult function will be improved later.
    */
    const qrReaderSetting = useMemo<QRScannerSettings>(()=>{
        return {
            onResult: (result, error) => {
                if (!!result) {
                    const qrCodeInputItem = Object.values(props.inputItems).find((item) => item.isQRCodeInput && item.name);
                    if(qrCodeInputItem?.name){
                        props.onInputChange(qrCodeInputItem.name, result.getText(), qrCodeInputItem.validator);
                    }
                }

                // if error.message === undefined, then reader did not detect qr code
                // if error.message !== undefined, then reader detected qrCode but failed to decode
                // One of the common decoding failures is: "e: Dimensions could be not found."
                if (!!error && error.message !== undefined) {
                    // if error returned, then log message and inform a user reading qr code failed 
                    dispatcher(openSnackbar({
                        isOpen: true,
                        type: NotifType.ERROR,
                        // The defualt message is failed to read qr code
                        message: qrScanErrorMessages[error.message] ?? qrScanErrorMessages['defaultReadFailMessage'], 
                        duration: 2000,
                    }));
                }   
            },
            scanDelay: 100,

            // This restricts the size of video showing on the UI.
            // It is difficult to find optimal minWidth to fit all smartphones.
            containerStyle: {
                minWidth: MIN_CONTAINER_WIDTH,
                maxWidth: MAX_CONTAINER_WIDTH,
                maxHeight: MAX_CONTAINER_HEIGHT,   
            },
            videoContainerStyle: {
                // By default paddingTop is set at 100%. Making adjustment to fit it correctly.
                paddingTop: '85%',
            },
            // facingMode=user means defaults to use camera on the screen
            constraints: {
                facingMode: 'user'
            },
        }
    },[dispatcher, props])

    return (
        <FormContainerStyleBox>
            <Box className='titleContainer'>
                {props.title}
            </Box>
            <Box className='userRegistrationContainer'>
                <Box className='qrReaderContainer'>
                    <QrReader
                        {...qrReaderSetting}
                    />
                </Box>
                <FormControl 
                    className='formContainer'
                >
                    {Object.values(props.inputItems).reduce((result: (UserRegistrationInputFields & {readonly name:string})[], item) => {
                        if(item.name){
                            result.push({
                                ...item,
                                name: item.name,
                            })
                        }
                        return result;
                    }, []).map((item)=>{
                        const input = props.formInputs[item.name].input;
                        return(
                            <Box
                                className='textFieldContainer'
                                key={item.name}
                            >
                                <CustomTextFieldComponent
                                    className={clsx('textField', item.clearInputButton && 'withClearButton')}                                   
                                    {...item}
                                    value={input}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        props.onInputChange(item.name, event.target.value, item.validator)
                                    }}

                                    // Check if the input is valid. Empty input is neither true or false, so giving undefined. 
                                    isValidInput={input !== '' ? 
                                        item.validator(input): undefined
                                    }
                                    // When input is invalid (empty does not count) use send helpertext to notify user.
                                    helperText={input !== '' && !item.validator(input) ? 
                                        item.helperText: undefined
                                    }
                                />
                                {item.clearInputButton && 
                                    <Button
                                        className='clearButton'
                                        variant='contained'
                                        onClick={()=>{props.onInputChange(item.name, '', item.validator)}}                                        
                                    >
                                        消去
                                    </Button>
                                }
                            </Box>
                        )
                    })}
                    <Button 
                        className='submitButton'
                        variant='contained'
                        onClick={props.onSubmit}
                        disabled={Object.values(props.formInputs).some((item) => !item.isValid)}
                    >
                        {props.buttonText}
                    </Button>
                </FormControl>
            </Box>
        </FormContainerStyleBox>
    )
}