import { Box, createTheme } from "@mui/material";
import {DrawerContainer} from "./DrawerContainer";

export const Layout = ({children}) => {
    const theme = createTheme();
    const DRAWER_WIDTH = 200;
    const SIDE_MARGIN = 3;

    return (
        <Box
            sx={{
                overflow: 'hidden',
                display: 'grid',
                gridTemplateAreas: `'menu main'`,
                gridTemplateColumns: `${DRAWER_WIDTH}px 1fr`,
                [`${theme.breakpoints.down('lg')}`]: {
                    gridTemplateColumns: '0px 1fr',
                  },
                gridGap: theme.spacing(SIDE_MARGIN),
                marginRight: theme.spacing(SIDE_MARGIN),
            }}
        >
            <Box sx={{ gridArea: 'menu'}}>
                <DrawerContainer />
            </Box>
            <Box className='grid-main' 
                sx={{gridArea: 'main', overflow: 'auto', marginTop: `64px`}}>
                {children}
            </Box>                  
        </Box>
    )
}