import { Box, Modal, Fade } from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import { RootState } from "../../redux/store"
import { closeImageModal } from "../../redux/toggleImageModalSlice"
import { useEffect, useMemo } from "react";
import { ApiResponseCodesEnum } from "../../utils/restAPI";

// Importing mp3 files
import enteredSound from './sounds/already_entered_sound.mp3';
import entryFailureSound from './sounds/entry_failure_sound.mp3';

// Importing png files
import entrySuccessImage from './images/entry_successful.png';
import alreadyEnteredImage from './images/already_entered.png';
import unableDateEntryImage from './images/unable_date_entry.png';
import ticketNotFoundImage from './images/ticket_not_found.png';
import ticketExpiredImage from './images/ticket_expired.png';
import dbOperationFailedImage from './images/db_operation_failed.png';
import generalErrorImage from './images/general_error.png';


const soundAndImageSettingForResponseCodes: {
    [key in ApiResponseCodesEnum]?: {
        image: string,
        sound: HTMLAudioElement,
    }
} = {
    [ApiResponseCodesEnum.SUCCESS]:{
        image: entrySuccessImage,
        sound: new Audio(enteredSound),
    },
    [ApiResponseCodesEnum.ALREADY_ENTERED]:{
        image: alreadyEnteredImage,
        sound: new Audio(enteredSound),
    },
    [ApiResponseCodesEnum.UNABLE_DATE_ENTRY]:{
        image: unableDateEntryImage,
        sound: new Audio(entryFailureSound),
    },
    [ApiResponseCodesEnum.TICKET_EXPIRED]:{
        image: ticketExpiredImage,
        sound: new Audio(entryFailureSound),
    },
    [ApiResponseCodesEnum.TICKET_NOT_FOUND]:{
        image: ticketNotFoundImage,
        sound: new Audio(entryFailureSound),   
    },
    [ApiResponseCodesEnum.DB_OPERATION_FAILED]: {
        image: dbOperationFailedImage,
        sound: new Audio(entryFailureSound),
    },
}

export const ImageModalComponent = () => {
    const {isOpen, duration, playSound, responseCode} = useAppSelector((state:RootState)=> state.imageModalReducer);
    const dispatcher = useAppDispatch();

    if(isOpen){
        setTimeout(()=>{
            dispatcher(closeImageModal());
        }, duration);
    }

    /*
    * This hook is used to play the retrive and play the appropriate sound based on response code
    */
    useEffect(() => {
        if(playSound){
            soundAndImageSettingForResponseCodes[responseCode]?.sound.play() ?? new Audio(entryFailureSound).play();
        }
    },[playSound, responseCode]);

    /*
    * This handler returns image tag based on the response code from the backend. 
    */
    const modalImageData = useMemo(() => {
        if(isOpen){
            return (
                <img
                    src={soundAndImageSettingForResponseCodes[responseCode]?.image ?? generalErrorImage}
                    alt="QR Scan Result"
                    style={{ 
                        maxHeight: "90%", 
                        maxWidth: "90%",       
                        outline: 'none',
                    }}
                />
            )
        }
    },[isOpen, responseCode]);


    return (
        <Box>
             <Modal
                open={isOpen}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500
                }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: "center",
                }}
             >
                <Fade
                    in={isOpen}
                >
                    {modalImageData ?? <img alt='No Modal'/>}
                </Fade>
            </Modal>
        </Box>
    )
}
