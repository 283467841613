import { Box } from '@mui/system';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { createTheme } from '@mui/material';

export const NotFoundPage = () => {
    const theme = createTheme();

    return (
        <Box
            sx={{
                marginTop: theme.spacing(3),
                textAlign: 'center',
            }}
        >
            <Box className='pageIconContainer'>
                <SentimentVeryDissatisfiedIcon 
                    className='faceIcon'
                    sx={{ 
                        width:100,
                        height:100,
                    }}
                />
            </Box>
            <Box 
                className='pageLabelContainer'
                sx={{
                    fontSize: 32,
                }}
            >
                ページが見つかりません
            </Box>
        </Box>
    )
}