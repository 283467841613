import { DrawerComponent, DrawerMenuFields } from "../components/DrawerComponent";
import { PROJECT_PATH } from "../routes/routes";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useMemo } from "react";


export const DrawerContainer = () => {

    const menuItems = useMemo<DrawerMenuFields[]>(() => {
        return [
            {
                'text': "入場登録", 
                'icon': <QrCode2Icon/>,
                'link': PROJECT_PATH.USER_ENTRY,
            }, 
            {
                "text": "顧客登録", 
                "icon": <HowToRegIcon/>,
                'link': PROJECT_PATH.USER_REGISTRATION,
            },
        ];
    }, [])

    return (
        <DrawerComponent 
            appName={"チケットDX"}
            drawerMenuItems={menuItems}                
        />
    )
}