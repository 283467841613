export const HomePage = () => {
    return (
        <div>
            <h2>
                顧客登録の作業手順
            </h2>
            <ol>
                <li>左メニュー内の「顧客登録」をクリック</li>
                <li>ブースに置いてあるQRコード用紙を申し込み人数分取る</li>
                <li>自動で立ち上がったカメラにQRコードを読み取る</li>
                <li>［QRコード情報］に自動で文字列が入る</li>
                <li>申し込み完了メールに記載の［キッズパスID］を入力</li>
                <li>申し込み者の［セイ］と［メイ］を全角カナで入力</li>
                <li>内容確認後「顧客登録」をクリック</li>
                <li>完了</li>
            </ol>
        </div>
    );
}

