import { UserRegistrationInputFieldsSet, UserRegistrationInputItems, UserRegistrationPage } from "../components/UserRegistrationPage";
import { useCallback, useState } from "react";
import { checkAllDecimalWithBiggerThanFiveDigits, checkFullWidthKatakana, checkV4UUID } from "../utils/utils";
import { postNewSupporter, PostNewSupporterRequestData } from "../utils/restAPI";
import { openSnackbar } from "../redux/toggleSnackbarSlice";
import { NotifType } from "../components/customSnackbarComponent/CustomSnackbarComponent";
import { useAppDispatch } from "../redux/hooks";
import { ApiError } from "../utils/error";
import { errorCodeToSnackbarMessage } from "../utils/common";


const inputItems: UserRegistrationInputFieldsSet = {
    membershipId: {
        required: true,
        name: 'membershipId',
        label:'キッズパスID(半角数字)',
        placeholder:'キッズパスID',
        helperText:'半角数字入力',
        validator: checkAllDecimalWithBiggerThanFiveDigits,
    },
    lastName: {
        required: true,
        name: 'lastName',
        label:'セイ(全角カナ)',
        placeholder:'セイ',
        helperText:'全角入力',
        validator: checkFullWidthKatakana,
    },
    firstName: {
        required: true,
        name: 'firstName',         
        label:'メイ(全角カナ)',
        placeholder:'メイ',
        helperText:'全角入力',
        validator: checkFullWidthKatakana,
    },
    ticketCode: {
        required: true,
        name: 'ticketCode',
        label:'QRコード情報',
        placeholder:'QRコード情報',
        isQRCodeInput: true,
        helperText:'正しい情報ではありません',
        isReadonly: true,
        clearInputButton: true,
        validator: checkV4UUID,
    },
}

interface UserInputState {
    input: string
    isValid: boolean
}

const initialFormInputs: UserRegistrationInputItems = {
    membershipId: {
        input: '',
        isValid: false,            
    },
    lastName: {
        input: '',
        isValid: false,
    },
    firstName: {
        input: '',
        isValid: false,
    },
    ticketCode: {
        input: '',
        isValid: false,
    },
};

export const UserRegistrationContainer = () => {
    const dispatcher = useAppDispatch();

    const [formInputs, setFormInputs] = useState<UserRegistrationInputItems>(initialFormInputs);

    const handleSubmit = useCallback(async () => {
        const requestData = Object.keys(formInputs).reduce((result, key) => {
            result[key] = formInputs[key]['input']
            return result;
        }, {} as PostNewSupporterRequestData);
        const response = await postNewSupporter(requestData);

        if(response instanceof ApiError){
            dispatcher(openSnackbar({
                isOpen: true,
                type: NotifType.ERROR,
                message: errorCodeToSnackbarMessage[response.code] ?? '登録失敗',
                duration: 2000,
            }));
        } else {
            dispatcher(openSnackbar({
                isOpen: true,
                type: NotifType.SUCCESS,
                message: `登録成功`,
                duration: 2000,
            }));
            
            setFormInputs(initialFormInputs);
        }
    }, [dispatcher, formInputs])

    const handleChange = useCallback((name: string, value: string, validator: (input: string) => boolean) => {
            setFormInputs((currentFormInputs) => {
                const updatedInput: UserInputState = {
                    input: value, 
                    isValid: validator(value),
                };

                return {
                    ...currentFormInputs,
                    [name]: updatedInput,
                }
            });
    }, []);  

    return (
        <UserRegistrationPage
            title={'顧客登録(キッズパスポート)'}
            inputItems={inputItems}
            buttonText={'顧客登録'}
            formInputs={formInputs}
            onSubmit={handleSubmit}
            onInputChange={handleChange}
        />
    )
}
