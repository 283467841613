import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './containers/App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { CustomSnackbarComponent } from './components/customSnackbarComponent/CustomSnackbarComponent';
import { ImageModalComponent } from './components/imageModalComponent/ImageModalComponent';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App /> 
      <CustomSnackbarComponent/>
      <ImageModalComponent/>
    </BrowserRouter>
  </Provider>
);
