import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseCodesEnum } from "../utils/restAPI";

export interface ToggleImageModalState {
    readonly isOpen: boolean
    readonly duration: number
    readonly responseCode: ApiResponseCodesEnum | string
    readonly playSound?: boolean | undefined
}

const initialState: ToggleImageModalState = {
    isOpen: false,
    duration: 0,
    responseCode: '',
    playSound: undefined,
}

export const imageModalSlice = createSlice({
    name:'imageModalSlice',
    initialState,
    reducers: {
        openImageModal:(state, action:PayloadAction<ToggleImageModalState>) => {
            const { isOpen, duration, playSound, responseCode } = action.payload;
            state.isOpen = isOpen;
            state.duration = duration;
            state.responseCode = responseCode;
            state.playSound = playSound;
        },
        closeImageModal:(state) => {
            state.isOpen = false;
            state.duration = 0;
            state.responseCode = '';
            state.playSound = false;
        },
    },
});

export const { openImageModal, closeImageModal } = imageModalSlice.actions;
export default imageModalSlice.reducer;