export class ApiError extends Error {
    public code: string;
    public details?: object | undefined
    public message: string;

    constructor(code: string, message: string, details?: object){
        super();
        this.code = code;
        this.details = details;
        this.message = message;
    }
}