import Drawer from '@mui/material/Drawer';
import { useState, useMemo, useCallback } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { AppBar, Box, CssBaseline, Divider, IconButton} from '@mui/material';
import { Link } from "react-router-dom";
import { PROJECT_PATH } from '../routes/routes';


const DRAWER_WIDTH = 200;

export interface DrawerMenuFields {
    readonly text: string
    readonly icon: JSX.Element
    readonly link: string
}

export interface DrawerMenuProp {
    readonly appName: string
    readonly drawerMenuItems: readonly DrawerMenuFields[]
}


export const DrawerComponent = (props: DrawerMenuProp) => {
    const [open, setOpen] = useState(false);
    const handleDrawerToggle = useCallback(() => setOpen(open => !open),[]);

    const drawers = useMemo(() => { 
        return (
            <div>
                <Link to={PROJECT_PATH.HOME} style={{ textDecoration: 'none' }} onClick={handleDrawerToggle}>
                    <Toolbar
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                            fontSize: 24,
                            fontWeight: 'bold',
                            color: '#FFF'
                        }}
                    >
                        {props.appName}
                    </Toolbar>                
                </Link>
                <Divider />
                <List>
                {props.drawerMenuItems.map((drawer) => (
                    <Link key={drawer.text} to={drawer.link} style={{ textDecoration: 'none' }} onClick={handleDrawerToggle}>
                        <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {drawer.icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={drawer.text}
                                primaryTypographyProps={{color: '#FFF', fontSize: 20, fontWeight: 'bold'}}
                            >
                            </ListItemText>
                        </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
                </List>
            </div>
        )
    }, [handleDrawerToggle, props.appName, props.drawerMenuItems]);

    return (
        <Box sx={{ display: 'flex'}}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
                    ml: { lg: `${DRAWER_WIDTH}px` },
                    backgroundColor: "#284b93"
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: DRAWER_WIDTH }, flexShrink: { lg: 0 }}}
            >
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, 
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, backgroundColor:'#6f96bc' },
                    }}
                >
                    {drawers}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH,  backgroundColor:'#6f96bc' },
                        backgroundColor: "#6f96bc"
                    }}
                    open
                >
                        {drawers}
                </Drawer>
            </Box>
        </Box>
    )
}