
/*
* Ideally, size restriction should be an argument, but this function only used in UserRegistrationPage,
* so there isn't much risk of adding size restriction
*/
export const checkAllDecimalWithBiggerThanFiveDigits = (input: string): boolean => {
    const regex = /^\d{5,}$/g;
    return regex.test(input);
}

export const checkFullWidthKatakana = (input: string): boolean => {
    // All input has to be full-width Katanaka
    const regex = /^[ァ-ン]+$/g;
    return regex.test(input);
}

export const checkV4UUID = (input: string): boolean => {
    // Check if the given input is UUID ver. 4
    const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/ig;
    return regex.test(input);
}

/*
* Handler returns date in yyyy-mm-dd format
*/
export const getToday = (): string => {
    const today = new Date();
    return (
        today.toLocaleDateString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).replaceAll("/", "-")
    )
}