import { useEffect, useState } from "react";
import { NotifType } from "../components/customSnackbarComponent/CustomSnackbarComponent";
import { UserEntryPage } from "../components/UserEntryPage"
import { useAppDispatch } from "../redux/hooks";
import { openSnackbar } from "../redux/toggleSnackbarSlice";
import { SeasonGameList } from "../utils/common";
import { ApiError } from "../utils/error";
import { getGames, GetGamesQueryParams,  } from "../utils/restAPI";
import { getToday } from "../utils/utils";
import { errorCodeToSnackbarMessage } from "../utils/common";

export const UserEntryContainer = () => {
    const [gameList, setGameList] = useState<SeasonGameList | undefined>(undefined);
    const dispatcher = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            const queryParams: GetGamesQueryParams = {
                newerThan: getToday(),
                limit: 1,
            };
            const response = await getGames(queryParams);

            if(response instanceof ApiError){
                dispatcher(openSnackbar({
                    isOpen: true,
                    type: NotifType.ERROR,
                    message: errorCodeToSnackbarMessage[response.code] ?? `試合情報の受信に失敗`,
                    duration: 2000,
                }));
            } else {
                if(response.total > 0){
                    setGameList(response);
                }
            }
          }
          fetchData();
    }, [dispatcher]);

    return (
        <div>
            <UserEntryPage
                title={'入場登録(キッズパスポート)'}
                qrReaderLabel={'*QRコードを画面中央にかざしてください'}
                currentGame={gameList && gameList.games[0]}
            />
        </div>
    )
}
