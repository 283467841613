import { Routes, Route } from "react-router-dom";
import { HomePage } from "../components/HomesPage";
import { UserRegistrationContainer } from "../containers/UserRegistrationContainer";
import { UserEntryContainer } from "../containers/UserEntryContainer";
import { Layout } from "../containers/Layout";
import { NotFoundPage } from "../components/NotFoundPage";


export const PROJECT_PATH = Object.freeze({
    HOME : '/',
    USER_REGISTRATION: '/register', 
    USER_ENTRY: '/entry',
});


export const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route 
                path={PROJECT_PATH.HOME}
                element={
                    <Layout>
                        <HomePage/>
                    </Layout>
                }
            />
            <Route 
                path={PROJECT_PATH.USER_REGISTRATION} 
                element={
                    <Layout>
                        <UserRegistrationContainer/>
                    </Layout>
                }
            />
            <Route 
                path={PROJECT_PATH.USER_ENTRY} 
                element={
                    <Layout>
                        <UserEntryContainer/>
                    </Layout>
                }
            />
            <Route 
                path='*'
                element={
                    <Layout>
                        <NotFoundPage/>
                    </Layout>
                }
            />
        </Routes>
    )
}