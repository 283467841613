import { CSSProperties } from "react"
import { ApiResponseCodesEnum } from "./restAPI"

export interface QRScannerSettings {
    //Handler that handle scan result
    readonly onResult: (result, error) => void,

    // how often scan runs in ms
    readonly scanDelay?: number | undefined

    // This restricts the size of video showing on the UI.
    // It is difficult to find optimal minWidth to fit all smartphones.
    containerStyle?: CSSProperties | undefined,
    videoContainerStyle?: CSSProperties | undefined,
    constraints: MediaTrackConstraints
}

export interface TeamInfo {
    readonly createdAt: string
    readonly modifiedAt: string
    readonly kind: string
    readonly code: string
    readonly name: string
}

export interface GameInfo {
    readonly eventUuid: string
    readonly gameType: string
    readonly title: string
    readonly team1: TeamInfo
    readonly team2: TeamInfo
    readonly eventStartAt: string
    readonly eventEndAt: string | null // response backend return null instead of undefined
}

export interface SeasonGameList {
    readonly games: readonly GameInfo[]
    readonly total: number
}

/*
* Error type depends on where error originated. 
* Sometimes error comes from browers in the form of DOMException. So, it does not have custom methods to obtain error type. 
* When error comes from the library (reader, decoder, etc), the error is custom error (but error type is "e"), and does contain method to extract to information to determine type.
* Since there are many cases to handle, the most simplest approach is to determine the error based on the error message.
*/
export const qrScanErrorMessages = Object.freeze({
    'Permission denied': 'カメラへのアクセスをONにしてください',
    'Requested device not found': 'カメラが見つかりませんでした',
    'defaultReadFailMessage': '読み込み失敗',
});

export const errorCodeToSnackbarMessage: {[key in ApiResponseCodesEnum]?: string} = Object.freeze({
    [ApiResponseCodesEnum.ALREADY_ASSIGNED]:'登録済み',
    [ApiResponseCodesEnum.ALREADY_ENTERED]:'入場済み',
    [ApiResponseCodesEnum.TICKET_EXPIRED]: 'チケットの有効期限切れ',
    [ApiResponseCodesEnum.TICKET_NOT_FOUND]:'対象チケットが見つかりませんでした',
    [ApiResponseCodesEnum.UNABLE_DATE_ENTRY]:'無効の日付です',
    [ApiResponseCodesEnum.DB_OPERATION_FAILED]:'サーバーエラー',
});